export enum FeatureToggleIdentifier {
  SHOW_PLAYGROUND = "SHOW_PLAYGROUND",
  ENABLE_INTERNATIONAL_PHONE_NUMBER = "ENABLE_INTERNATIONAL_PHONE_NUMBER",
  ENABLE_MARKETPLACE = "ENABLE_MARKETPLACE",
  ENABLE_AUTH_IDENTIFICATION_TYPE_SELECTION = "ENABLE_AUTH_IDENTIFICATION_TYPE_SELECTION",
  ENABLE_APP_AUTO_RELOAD = "ENABLE_APP_AUTO_RELOAD",
  ENABLE_MARKETPLACE_CALL_BACK = "ENABLE_MARKETPLACE_CALL_BACK",
  ENABLE_INTERCARE_MEDICAL_ASSISTANCE = "ENABLE_INTERCARE_MEDICAL_ASSISTANCE",
  ENABLE_INTERNATIONAL_PHONE_NUMBER_UG = "ENABLE_INTERNATIONAL_PHONE_NUMBER_UG",
  ENABLE_FACE_SCAN_PRODUCT_RULES = "ENABLE_FACE_SCAN_PRODUCT_RULES",
  ENABLE_ADD_DEPENDANTS = "ENABLE_ADD_DEPENDANTS",
  ENABLE_GET_HELP_LOG_QUERY_FORM = "ENABLE_GET_HELP_LOG_QUERY_FORM",
  ENABLE_CHAT_BOT = "ENABLE_CHAT_BOT",
  ENABLE_IN_APP_MESSAGING = "ENABLE_IN_APP_MESSAGING",
  ENABLE_COLLECT_LEAD_SOURCE = "ENABLE_COLLECT_LEAD_SOURCE",
  ENABLE_DOCTOR_ON_DEMAND = "ENABLE_DOCTOR_ON_DEMAND",
  ENABLE_ADD_DEPENDANTS_WITHOUT_IDENTITY_DOCUMENT_DETAILS = "ENABLE_ADD_DEPENDANTS_WITHOUT_IDENTITY_DOCUMENT_DETAILS",
  ENABLE_THIRD_PARTY_AUTHENTICATION = "ENABLE_THIRD_PARTY_AUTHENTICATION",
}

const featureToggles = mapIdentifierKeysToEnvironmentVariables();

function getFeatureToggleDataStore(): any {
  if (typeof window.Cypress !== "undefined") {
    try {
      return JSON.parse(
        window.localStorage.getItem("__cypress.featureToggles") || "{}"
      );
    } catch (error) {
      return { error };
    }
  } else {
    return featureToggles;
  }
}

function mapIdentifierKeysToEnvironmentVariables() {
  return Object.values(FeatureToggleIdentifier)
    .map((featureIdentifier) => {
      return {
        key: featureIdentifier,
        value:
          import.meta.env[`VITE_APP_FEATURE_${featureIdentifier}`] === "true"
            ? true
            : false,
      };
    })
    .reduce((accumulator: any, currentValue: any) => {
      accumulator[currentValue.key] = currentValue.value;
      return accumulator;
    }, {});
}

export function isFeatureEnabled(
  featureIdentifier: FeatureToggleIdentifier | string
): boolean {
  return getFeatureToggleDataStore()[featureIdentifier];
}
