import {
  trackPageViewV2,
  trackFormEvent,
  AnalyticsEvent,
} from "@/services/analytics-adapter";
import { useGlobalStore } from "@/store";
import { GetHelpFABConfig } from "@/theme";
import { FaceIconBrandLarge } from "@/theme/icons";
import { Stack, Typography, InputLabel } from "@mui/material";
import { useId, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import GetHelpCTAButton from "@/components/GetHelpCTAButton";
import InternationalPhoneNumberInput, {
  InternationalPhoneNumber,
} from "@/components/InternationalPhoneNumberInput";
import ButtonWithAnalytics from "@/components/ButtonWithAnalytics";

interface CollectPhoneNumberProps {
  onSubmit: (phoneNumber: InternationalPhoneNumber) => void;
  value?: { globalSubscriberNumber: string; countryCode: string };
}

export default function CollectPhoneNumber(props: CollectPhoneNumberProps) {
  const { t } = useTranslation();
  const inputID = useId();
  const { state } = useGlobalStore();
  const defaultCountryCode = "ZA";
  const [phoneNumber, setPhoneNumber] = useState<InternationalPhoneNumber>({
    countryCode: state.countryCode || defaultCountryCode,
    globalSubscriberNumber: props.value?.globalSubscriberNumber || "",
  });

  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);

  useEffect(() => {
    trackPageViewV2({
      pageName: "Sign up:Cellphone number",
      pageSubSection1: "Sign up",
      pageSubSection2: "Sign up:Cellphone number",
      pageCategory: "Sign up",
    });

    trackFormEvent(AnalyticsEvent.FORM_START, {
      formName: "Enter cellphone number",
    });
  }, []);

  function onChange(phoneNumber: InternationalPhoneNumber, isValid: boolean) {
    setIsSubmitButtonEnabled(isValid);
    setPhoneNumber(phoneNumber);
  }

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    props.onSubmit(phoneNumber);
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <Stack spacing={2} justifyContent="flex-end" sx={{ pb: 2 }}>
          <FaceIconBrandLarge />
          <Stack spacing={2}>
            <Typography component="h2" variant="h3">
              {t("CollectPhoneNumber.CellphoneNumberInputDescription")}
            </Typography>
          </Stack>
          <Stack pb={4}>
            {(
              t<any, any, string[]>(
                "CollectPhoneNumber.CellphoneNumberInputSubtitle",
                {
                  returnObjects: true,
                }
              ) as []
            ).map((text: string, index: number) => (
              <Typography key={String(text + index)} variant="body1">
                {text}
              </Typography>
            ))}
          </Stack>

          <InputLabel htmlFor={inputID}>
            {t("CollectPhoneNumber.CellphoneNumberInputLabel")}
          </InputLabel>
          <InternationalPhoneNumberInput
            value={phoneNumber}
            onChange={onChange}
          />
          <Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignSelf="flex-end"
              position="relative"
              width={GetHelpFABConfig.floatingActionButtonWidth}
              py={1}
            >
              <GetHelpCTAButton
                pageName="CollectPhoneNumber"
                eventName="action.contactSupportFab"
              />
            </Stack>

            <ButtonWithAnalytics
              page="CollectPhoneNumber"
              intent="navigational"
              text={t("common.nextButton")}
              type="submit"
              color="primary"
              disabled={!isSubmitButtonEnabled}
              aria-label={t("common.nextButton")}
              fullWidth
            >
              {t("common.nextButton")}
            </ButtonWithAnalytics>
          </Stack>
        </Stack>
      </form>
    </>
  );
}
