import {
  trackPageViewV2,
  trackFormEvent,
  AnalyticsEvent,
} from "@/services/analytics-adapter";
import { GetHelpFABConfig } from "@/theme";
import { FaceIconBrandLarge } from "@/theme/icons";
import { Stack, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FormInputErrorList from "@/components/FormInputErrorList";
import GetHelpCTAButton from "@/components/GetHelpCTAButton";
import NoOTPReceivedModal from "@/components/NoOTPReceivedModal";
import OTPInput from "@/components/OTPInput";
import ButtonWithAnalytics from "@/components/ButtonWithAnalytics";

const OTP_MAX_LENGTH = 6;
export interface Props {
  phoneNumber: { globalSubscriberNumber: string; countryCode: string };
  onSubmit: (otpValue: string) => void;
  errors?: Array<string>;
  resendOTP?: () => void;
}

export default function CollectOTP(props: Props) {
  const { t } = useTranslation();
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [otpValue, setOTPValue] = useState("");
  const [clearInputValue, setClearInputValue] = useState(false);

  useEffect(() => {
    trackPageViewV2({
      pageName: "Sign up:OTP",
      pageSubSection1: "Sign up",
      pageSubSection2: "Sign up:OTP",
      pageCategory: "Sign up",
    });

    trackFormEvent(AnalyticsEvent.FORM_START, {
      formName: "Enter OTP",
    });
  }, []);

  useEffect(() => {
    updateNextButtonDisabledState(otpValue);
  }, [otpValue]);

  function updateNextButtonDisabledState(value: string) {
    if (value.length === OTP_MAX_LENGTH) {
      setIsNextButtonDisabled(false);
    } else {
      setIsNextButtonDisabled(true);
    }
  }

  function onModalOpenButtonClick() {
    setIsModalOpen(true);
  }

  function onModalClose() {
    setIsModalOpen(false);
  }

  function onOTPChange(value: string) {
    updateNextButtonDisabledState(value);
    setOTPValue(value);
    setClearInputValue(false);
  }

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    props.onSubmit(otpValue);
    setClearInputValue(true);
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <Stack
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ pb: 2 }}
        >
          <Stack
            spacing={2}
            sx={{
              maxWidth: "25rem",
              marginLeft: "auto",
              marginRight: "auto",
              width: "100%",
            }}
          >
            <OTPInput
              onChange={onOTPChange}
              inputPattern="***-***"
              clearInputValue={clearInputValue}
            />
            <FormInputErrorList errors={props.errors || []} />
          </Stack>
          <Stack pt={6} spacing={2}>
            <FaceIconBrandLarge />
            <Stack spacing={2}>
              <Typography variant="h3">{t("CollectOTP.Title")}</Typography>
              <Typography variant="body1">
                {t("CollectOTP.Description", {
                  phoneNumber: props.phoneNumber.globalSubscriberNumber,
                })}
              </Typography>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignSelf="flex-end"
              position="relative"
              width={GetHelpFABConfig.floatingActionButtonWidth}
              pt={1}
            >
              <GetHelpCTAButton
                pageName="CollectOTP"
                eventName="action.contactSupportFab"
              />
            </Stack>

            <ButtonWithAnalytics
              page="CollectOTP"
              intent="navigational"
              text={t("common.nextButton")}
              type="submit"
              color="primary"
              disabled={isNextButtonDisabled}
              aria-label={t("common.nextButton")}
            >
              {t("common.nextButton")}
            </ButtonWithAnalytics>
            <ButtonWithAnalytics
              page="CollectOTP"
              intent="navigational"
              text={t("CollectOTP.NoOTPReceivedButton")}
              aria-label={t("CollectOTP.NoOTPReceivedButton")}
              onClick={onModalOpenButtonClick}
              variant="text"
            >
              {t("CollectOTP.NoOTPReceivedButton")}
            </ButtonWithAnalytics>
          </Stack>
        </Stack>
      </form>

      <NoOTPReceivedModal
        isOpen={isModalOpen}
        onClose={onModalClose}
        extras={{
          phoneNumber: props.phoneNumber.globalSubscriberNumber,
          resendOTP: props.resendOTP,
        }}
      />
    </>
  );
}
