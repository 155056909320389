import mixpanel from "mixpanel-browser";
import { AppDistributionIdentifier } from "../../store";
import { retrieveValue } from "../../store/persistence";

window.dataLayer = window.dataLayer || [];
window.adobeDataLayer = window.adobeDataLayer || [];

const deviceCheck = /Mobile/i.test(window.navigator.userAgent)
  ? "Mobile"
  : "Desktop";
const currentUser = retrieveValue("currentUser");

const DEFAULT_PAGE_VIEW_VALUES = {
  deviceType: deviceCheck,
  domainName: "unuhealth.org",
  loginStatus: currentUser ? "logged in" : "guest",
  siteBusinessUnit: "Group",
  siteCountry: "South Africa",
  websiteName: "Unu health",
  websiteNameCode: "UH",
  siteLanguage: "English",
  memberIdentifier: currentUser ? currentUser.memberId : "",
};

export interface EventPayload {
  event: string;
  [key: string]: any;
}

type DeviceType = "Mobile" | "Desktop";
export type AnalyticsLinkIntent =
  | "navigational"
  | "informational"
  | "comparison"
  | "transactional"
  | "confirmational"
  | "query"
  | "social";

export enum AnalyticsEvent {
  VIRTUAL_PAGE_VIEW = "virtualPageView",
  USER_INTERACTION = "userInteraction",
  FORM_START = "formStart",
  FORM_COMPLETE = "formComplete",
  APPLICATION_START = "applicationStart",
  APPLICATION_COMPLETE = "applicationComplete",
  ERROR = "errorEvent",
}

export enum AnalyticsErrorEvent {
  INTERNAL_SERVER_ERROR = "500 | internal server error",
  PAGE_NOT_FOUND = "404 | page not found",
  REQUEST_TIMED_OUT = "408 | request timed out",
  SERVICE_UNAVAILABLE = "503 | service unavailable",
}

interface AnalyticsPageViewEventPayload {
  pageName: string;
  pageSubSection1: string;
  pageSubSection2: string;
  pageCategory: string;
  domainName?: string;
  deviceType?: DeviceType;
  loginStatus?: string;
  memberIdentifier?: string;
  siteBusinessUnit?: string;
  siteCountry?: string;
  siteLanguage?: string;
  websiteName?: string;
  websiteNameCode?: string;
}

interface AnalyticsFormEventPayload {
  formName: string;
}

interface AnalyticsApplicationEventPayload {
  applicationName: string;
  applicationStep: string;
}

interface AnalyticsErrorEventPayload {
  errorMessage: string;
}

export interface AnalyticsUserInteractionEventPayload {
  linkText: string;
  linkIntent: AnalyticsLinkIntent;
  linkScope: string;
}

interface UserPayload {
  organizationId: string;
  memberId: string;
}

const MIXPANEL_PROJECT_TOKEN =
  import.meta.env.VITE_APP_MIXPANEL_PROJECT_TOKEN || "";

if (MIXPANEL_PROJECT_TOKEN) {
  mixpanel.init(MIXPANEL_PROJECT_TOKEN, {
    debug: true,
    persistence: "localStorage",
  });
}

export function setCurrentUser(payload: UserPayload) {
  try {
    mixpanel.people.set({
      ...payload,
    });
    mixpanel.identify();
  } catch (error) {
    console.error(error);
  }
}

export function trackAppDistribution(
  appDistributionIdentifier: AppDistributionIdentifier
) {
  if (appDistributionIdentifier && MIXPANEL_PROJECT_TOKEN) {
    mixpanel.register({ distributionIdentifier: appDistributionIdentifier });
  }
}

export function trackEvent(payload: EventPayload) {
  if (import.meta.env.VITE_APP_GOOGLE_TAG_MANAGER_URL_PARAMS) {
    window.dataLayer.push(payload);
  }
  if (MIXPANEL_PROJECT_TOKEN) {
    const { event, ...remainingPayload } = payload;
    mixpanel.track(event, remainingPayload);
  }
}

export function trackPageView(payload: any) {
  if (MIXPANEL_PROJECT_TOKEN) {
    mixpanel.track_pageview({
      ...payload,
      "page.url": payload["page.url"],
      "page.sourceUrl": payload["page.sourceUrl"],
      page: payload.pageTitle,
    });
  }
}

export function trackPageViewV2(payload: AnalyticsPageViewEventPayload) {
  const pageViewEvent = { ...DEFAULT_PAGE_VIEW_VALUES, ...payload };
  window.adobeDataLayer.push({
    event: AnalyticsEvent.VIRTUAL_PAGE_VIEW,
    page: pageViewEvent,
  });
}

export function trackUserInteraction(
  payload: AnalyticsUserInteractionEventPayload
) {
  window.adobeDataLayer.push({
    event: AnalyticsEvent.USER_INTERACTION,
    cta: payload,
  });
}

export function trackFormEvent(
  event: AnalyticsEvent.FORM_COMPLETE | AnalyticsEvent.FORM_START,
  payload: AnalyticsFormEventPayload
) {
  window.adobeDataLayer.push({ event: event, form: payload });
}

export function trackApplicationEvent(
  event: AnalyticsEvent.APPLICATION_START | AnalyticsEvent.APPLICATION_COMPLETE,
  payload: AnalyticsApplicationEventPayload
) {
  window.adobeDataLayer.push({ event: event, application: payload });
}

export function trackErrorEvent(payload: AnalyticsErrorEventPayload) {
  window.adobeDataLayer.push({
    event: AnalyticsEvent.ERROR,
    errorMessage: payload,
  });
}
