import React, {
  createContext,
  ReactElement,
  useContext,
  useMemo,
  useReducer,
} from "react";
import { trackEvent, setCurrentUser } from "../services/analytics-adapter";
import { persistValue, clearValue, retrieveValue } from "./persistence";

interface CurrentUser {
  memberId: string;
  cognitoUserId: string;
  firstName: string;
  lastName: string;
  organizationName: string;
  organizationImageUri: string;
  emailAddress: string;
  identityDocumentType: string;
  identityDocumentValue: string;
  isInformationConsentComplete: boolean;
  isOnboarded: boolean;
  mobileNumber: string;
  organizationId: string;
  isRetailMember?: boolean | null;
}

export enum SupportedLanguageCodes {
  en = "en",
  "pt-MZ" = "pt-MZ",
}

export enum AuthIdentificationType {
  ZA_ID_OR_INTERNATIONAL_PASSPORT = "ZA_ID_OR_INTERNATIONAL_PASSPORT",
  UNUID = "UNU_ID",
}

type FeatureHasBeenViewed = {
  createdAt: string;
  value: boolean;
};

export enum AppDistributionIdentifier {
  APPLE_APP_STORE = "APPLE_APP_STORE",
  GOOGLE_PLAY_STORE = "GOOGLE_PLAY_STORE",
  HUAWEI_APP_GALLERY = "HUAWEI_APP_GALLERY",
  WEB = "WEB",
  PWA = "PWA",
}

interface GlobalStoreContextState {
  isUserLoggedIn: boolean;
  currentUser: CurrentUser;
  hasMemberRequiredActionsBeenCompleted: boolean;
  hasCVPBeenViewed: FeatureHasBeenViewed;
  welcomeScreenLastViewed: FeatureHasBeenViewed;
  healthMeasurementsWithEditView: HealthMeasurementIdentifier[];
  healthMeasurementsWithDetailView: HealthMeasurementIdentifier[];
  userSelectedLanguage: {
    value: string | null;
  };
  userSelectedAuthIdentificationType: {
    value: string | null;
    identityDocumentSubType?: string | null;
  };
  redirectLink: string;
  countryCode: string;
  appDistributionIdentifier: AppDistributionIdentifier;
  isThirdPartyMember: boolean;
}

interface Action {
  type: string;
  payload: any;
}

export enum HealthMeasurementIdentifier {
  SexAtBirth = "SexAtBirth",
  DateOfBirth = "DateOfBirth",
  Age = "Age",
  MetabolicAge = "MetabolicAge",
  BodyMassIndex = "BodyMassIndex",
  Height = "Height",
  Weight = "Weight",
  SmokingStatusAndFrequency = "SmokingStatusAndFrequency",
  BodyFat = "BodyFat",
  BodyWaterPercent = "BodyWaterPercent",
  BloodPressure = "BloodPressure",
  BloodType = "BloodType",
  Pulse = "Pulse",
  SaturationOfPeripheralOxygen = "SaturationOfPeripheralOxygen",
  ConditionsStatusAndList = "ConditionsStatusAndList",
  AllergiesStatusAndList = "AllergiesStatusAndList",
  BreathingRate = "BreathingRate",
}

const initialState: GlobalStoreContextState = {
  isUserLoggedIn: retrieveValue("isUserLoggedIn") || false,
  currentUser: {
    ...(retrieveValue("currentUser") || {
      memberId: "",
      cognitoUserId: "",
      firstName: "",
      lastName: "",
      mobileNumber: "",
      emailAddress: "",
    }),
  },
  hasCVPBeenViewed: retrieveValue("hasCVPBeenViewed") || {
    createdAt: null,
    value: false,
  },
  welcomeScreenLastViewed: retrieveValue("welcomeScreenLastViewed") || {
    createdAt: null,
    value: false,
  },
  userSelectedLanguage: retrieveValue("userSelectedLanguage") || {
    value: null,
  },
  userSelectedAuthIdentificationType: retrieveValue(
    "userSelectedAuthIdentificationType"
  ) || {
    value: null,
    identityDocumentSubType: null,
  },
  healthMeasurementsWithEditView: [
    HealthMeasurementIdentifier.Weight,
    HealthMeasurementIdentifier.Height,
    HealthMeasurementIdentifier.SmokingStatusAndFrequency,
    HealthMeasurementIdentifier.ConditionsStatusAndList,
    HealthMeasurementIdentifier.AllergiesStatusAndList,
    HealthMeasurementIdentifier.BloodType,
  ],
  healthMeasurementsWithDetailView: [
    HealthMeasurementIdentifier.BloodPressure,
    HealthMeasurementIdentifier.BodyMassIndex,
    HealthMeasurementIdentifier.BodyWaterPercent,
    HealthMeasurementIdentifier.BodyFat,
    HealthMeasurementIdentifier.Pulse,
    HealthMeasurementIdentifier.SaturationOfPeripheralOxygen,
    HealthMeasurementIdentifier.BloodType,
    HealthMeasurementIdentifier.SmokingStatusAndFrequency,
    HealthMeasurementIdentifier.Weight,
    HealthMeasurementIdentifier.Height,
    HealthMeasurementIdentifier.MetabolicAge,
    HealthMeasurementIdentifier.BreathingRate,
  ],
  hasMemberRequiredActionsBeenCompleted: false,
  redirectLink: retrieveValue("redirectLink") || "",
  countryCode: retrieveValue("countryCode") || "",
  appDistributionIdentifier: AppDistributionIdentifier.WEB,
  isThirdPartyMember: retrieveValue("isUserThirdPartyMember") || false,
};

const GlobalReducer = (state: any, action: Action) => {
  switch (action.type) {
    case "SET_IS_USER_LOGGED_IN":
      persistValue("isUserLoggedIn", action.payload);

      if (action.payload === true) {
        trackEvent({
          event: "action.successfullyLoggedIn",
        });

        setCurrentUser({
          organizationId: state.currentUser.organizationId,
          memberId: state.currentUser.memberId,
        });
      }

      return {
        ...state,
        isUserLoggedIn: action.payload,
      };

    case "SET_CURRENT_USER": {
      const currentUser = { ...state.currentUser, ...action.payload };

      persistValue("currentUser", currentUser);
      return {
        ...state,
        currentUser: currentUser,
      };
    }

    case "CLEAR_CURRENT_USER":
      clearValue("isUserLoggedIn");
      clearValue("currentUser");
      return {
        ...state,
        isUserLoggedIn: false,
        currentUser: initialState.currentUser,
        hasMemberRequiredActionsBeenCompleted: false,
      };

    case "SET_CVP_HAS_BEEN_VIEWED":
      persistValue("hasCVPBeenViewed", {
        createdAt: new Date().toUTCString(),
        value: true,
      });
      return {
        ...state,
        hasCVPBeenViewed: {
          createdAt: new Date().toUTCString(),
          value: true,
        },
      };

    case "SET_WELCOME_SCREEN_LAST_VIEWED":
      persistValue("welcomeScreenLastViewed", {
        createdAt: new Date().toUTCString(),
        value: true,
      });
      return {
        ...state,
        welcomeScreenLastViewed: {
          createdAt: new Date().toUTCString(),
          value: true,
        },
      };

    case "CLEAR_WELCOME_SCREEN_LAST_VIEWED":
      persistValue("welcomeScreenLastViewed", { value: null });
      return {
        ...state,
        welcomeScreenLastViewed: { value: null },
      };

    case "SET_USER_SELECTED_LANGUAGE":
      persistValue("userSelectedLanguage", {
        value: action.payload.languageCode,
      });
      return {
        ...state,
        userSelectedLanguage: { value: action.payload.languageCode },
      };

    case "CLEAR_USER_SELECTED_LANGUAGE":
      persistValue("userSelectedLanguage", { value: null });
      return {
        ...state,
        userSelectedLanguage: { value: null },
      };

    case "SET_USER_AUTH_IDENTIFICATION_TYPE":
      persistValue("userSelectedAuthIdentificationType", {
        value: action.payload.userSelectedAuthIdentificationType,
        identityDocumentSubType:
          action.payload.userSelectedAuthIdentificationSubType,
      });
      return {
        ...state,
        userSelectedAuthIdentificationType: {
          value: action.payload.userSelectedAuthIdentificationType,
          identityDocumentSubType:
            action.payload.userSelectedAuthIdentificationSubType,
        },
      };

    case "CLEAR_USER_AUTH_IDENTIFICATION_TYPE":
      persistValue("userSelectedAuthIdentificationType", { value: null });
      return {
        ...state,
        userSelectedAuthIdentificationType: { value: null },
      };

    case "CLEAR_CVP_HAS_BEEN_VIEWED":
      persistValue("hasCVPBeenViewed", {
        createdAt: new Date().toUTCString(),
        value: false,
      });
      return {
        ...state,
        hasCVPBeenViewed: {
          createdAt: new Date().toUTCString(),
          value: false,
        },
      };

    case "SET_MEMBER_REQUIRED_ACTIONS_HAS_BEEN_COMPLETED":
      return {
        ...state,
        hasMemberRequiredActionsBeenCompleted: true,
      };

    case "SET_REDIRECT_LINK":
      persistValue("redirectLink", action.payload);
      return { ...state, redirectLink: action.payload };

    case "CLEAR_REDIRECT_LINK":
      clearValue("redirectLink");
      return { ...state, redirectLink: "" };

    case "SET_COUNTRY_CODE":
      persistValue("countryCode", action.payload);
      return { ...state, countryCode: action.payload };

    case "CLEAR_COUNTRY_CODE":
      clearValue("countryCode");
      return { ...state, countryCode: "" };

    default:
      return state;
  }
};

export const GlobalStoreContext = createContext<{
  state: GlobalStoreContextState;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

interface Props {
  appDistributionIdentifier: AppDistributionIdentifier;
  isThirdPartyMember: boolean;
  children: ReactElement | null;
}

export const GlobalStoreProvider: React.FC<Props> = (props) => {
  const [state, dispatch] = useReducer(GlobalReducer, {
    ...initialState,
    appDistributionIdentifier: props.appDistributionIdentifier,
    isThirdPartyMember: props.isThirdPartyMember,
  });
  const stateProviderValue = useMemo(
    () => ({ state, dispatch }),
    [state, dispatch]
  );
  return (
    <GlobalStoreContext.Provider value={stateProviderValue}>
      {props.children}
    </GlobalStoreContext.Provider>
  );
};

export const useGlobalStore = () => {
  return useContext(GlobalStoreContext);
};

export interface Country {
  id: string;
  label: string;
  internationalDiallingCode: string;
}

export const supportedCountries: Country[] = [
  { id: "ZA", label: "South Africa", internationalDiallingCode: "+27" },
  {
    id: "MZ",
    label: "Mozambique (Moçambique)",
    internationalDiallingCode: "+258",
  },
  {
    id: "UG",
    label: "Uganda",
    internationalDiallingCode: "+256",
  },
];

export function findCountryBasedOnCountryCode(countryCode: string): Country {
  const foundCountry = supportedCountries.find(
    (supportedCountry) => supportedCountry.id === countryCode
  );
  return foundCountry || supportedCountries[0];
}
